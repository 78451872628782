<template>
  <table-lite-custom
      :items="items"
      :fields="fields"
      :is-loading="isLoading"
  >
    <template #cell(ip_port)="{data}">
      <strong  v-if="data['item']['addon'] !== null" class="text-primary cursor-pointer" @click="data['toggleDetails']">{{ getIPPortFormat(data['item']['ip'], data['item']['port']) }}</strong>
      <strong  v-else class="text-primary">{{ getIPPortFormat(data['item']['ip'], data['item']['port']) }}</strong>
    </template>
    <template #cell(period)="{data}">
      <span @click="is_period_editing = true">
        <span :class="{ 'cursor-pointer': invoiceId === undefined }"><span class="text-danger font-weight-bold">{{ data['item']['period']}}</span> tháng</span>
      </span>
      <span v-if="is_period_editing && invoiceId === undefined">
        <b-button
            variant="flat-danger"
            class="btn-icon ml-1"
            size="sm"
            @click="data['item']['period'] > 1 ? data['item']['period']-- : data['item']['period']"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
        <b-button
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="data['item']['period']++"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
        <b-button
          variant="flat-primary"
          class="btn-icon"
          size="sm"
          @click="is_period_editing = false"
      >
          <feather-icon icon="CheckIcon" />
        </b-button>
      </span>
    </template>
    <template #row-details="{data}">
      <ul v-for="(addon, index) in data['item']['addon']" :key="index">
        <li>{{ addon['amount'] }} x <strong>{{ addon['name'] }}</strong></li>
      </ul>
    </template>
  </table-lite-custom>
</template>

<script>

import TableLiteCustom from "@/views/components/table/TableLiteCustom.vue";
import inputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'InvoiceRenew',
  components: {
    TableLiteCustom
  },
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    invoiceId: {
      default: undefined,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  watch: {
    items: {
      handler (value) {
        value.forEach(item => {
          if (item['addon']) {
            item['_showDetails'] = item['addon'].length > 0
          }
        })
      },
      deep: true
    }
  },
  data () {
    let self = this
    return {
      fields: [
        {
          key: 'ip_port',
          header2: '(1)',
          label: 'IP/Port',
          formatter (value) {
            return `<strong>${value}</strong>`
          }
        },
        {
          key: 'vm_plan',
          label: 'Gói dịch vụ',
          header2: '(2)',
          formatter (value, index, item) {
            return `${item['plan']['name']}`
          }
        },
        {
          key: 'period',
          label: 'Chu kỳ',
          header2: '(3)',
          formatter (value, index, item) {
            return item['period']
          }
        },
        {
          key: 'end_date',
          label: 'Ngày hết hạn',
          header2: '(4)',
          formatter (value, index, item) {
            return `Hạn cũ <strong>${self.convertToISODate(value)}</strong> | Hạn mới <strong>${self.momentAdd(value, item['period'], 'month')}</strong>`
          }
        },
        {
          key: 'price',
          label: 'Giá tiền',
          header2: '(5)',
          formatter (value) {
            return `${self.currencyFormat(value)}`
          }
        },
        {
          key: 'total_amount',
          label: 'Thành tiền',
          header2: '(6)=(3)x(5)',
          formatter (value, index, item) {
            return `<strong class="text-success">${self.currencyFormat(item['price'] * item['period'])}</strong>`
          }
        }
      ],
      is_period_editing: false
    }
  }
}
</script>
