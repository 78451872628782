<template>
  <table-lite-custom
      :items="items"
      :fields="fields"
      :is-loading="isLoading"
  >
    <template #cell(ip_port)="{data}">
      <strong  v-if="data['item']['addon'] !== null" class="text-primary cursor-pointer" @click="data['toggleDetails']">{{ getIPPortFormat(data['item']['ip'], data['item']['port']) }}</strong>
      <strong  v-else class="text-primary">{{ getIPPortFormat(data['item']['ip'], data['item']['port']) }}</strong>
    </template>
    <template #cell(description)="{data}">
      <span>Cấp mới ngày <strong>{{ convertToISODate(data['item']['created_at']) }}</strong> - Hạn dùng: <strong>{{ convertToISODate(data['item']['end_date']) }}</strong></span>
    </template>
    <template #row-details="{data}">
      <ul v-for="(addon, index) in data['item']['addon']" :key="index">
        <li>{{ addon['amount'] }} x <strong>{{ addon['name'] }}</strong></li>
      </ul>
    </template>
  </table-lite-custom>
</template>

<script>

import TableLiteCustom from "@/views/components/table/TableLiteCustom.vue";
import inputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'InvoiceRenew',
  components: {
    TableLiteCustom
  },
  mixins: [inputHelper],
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    invoiceId: {
      default: undefined,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  watch: {
    items: {
      handler (value) {
        value.forEach(item => {
          if (item['addon']) {
            item['_showDetails'] = item['addon'].length > 0
          }
        })
      },
      deep: true
    }
  },
  data () {
    let self = this
    return {
      fields: [
        {
          key: 'ip_port',
          label: 'IP/Port',
          formatter (value) {
            return `<strong>${value}</strong>`
          }
        },
        {
          key: 'vm_plan',
          label: 'Gói dịch vụ',
          formatter (value, index, item) {
            return `${item['plan']['name']}`
          }
        },
        {
          key: 'description',
          label: 'Miêu tả',
        },
        {
          key: 'total_amount',
          label: 'Thành tiền',
          formatter (value, index, item) {
            return `<strong class="text-success">${self.currencyFormat(item['price'] * item['period'])}</strong>`
          }
        }
      ],
      is_period_editing: false
    }
  }
}
</script>
